@import '../../../../utilities/css/color.module.scss';

.wrapper{
    display: grid;
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 200%;
    grid-template-columns: 15% 15% 40% 15% 15%;
    .small_but_cont{
        .icon{
            color:  $whitePrimary;
            cursor: pointer;
        }
        .icon_inactive{
            color: $primaryLight;
            cursor: none;
        }
        .icon:hover{
            color: $player-red;
        }
    }
    .play_pause_icon_container{
        color: $whitePrimary;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        .circle_background_for_play_icon{
            height: 1.7em;
            width: 1.7em;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            transition: height 0.2s, width 0.2s, border 0.2s;
        }
        .icon{
            font-size: 2em;
            cursor: pointer;
            border-radius: 50%;
            padding: 0 !important;
            margin: 0;
            padding: 0;
            transition: font-size 0.2s;
        }
        .circle_background_for_play_icon:hover{
            height: 1.8em;
            width: 1.8em;
            border: 0.3em solid $player-red;
            background: $player-red;
            .icon{
                background: $player-red;
                font-size: 2.2em;
            }
        }
    }
}