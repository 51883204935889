
.loading{
    width: 100%;
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .skeleton{
      width: 100%;
      height: 100%;
    }
    .logo{
      position: absolute;
      width: 100px;
    }
  }