@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;1,100;1,300&family=Shippori+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@800&display=swap');

$primaryFont: 'Shippori Mincho', serif;
$fontColorDark: #111111;
$blackPrimary: #000000;
$primeRed: #f86666;
$fontColorLight: #504e4e;
$headerFontFamily: 'Shippori Mincho', serif;
$whitePrimary: #ffffff;
$primaryLight: #a79f9f;
$logoColor: #346250;
$linkBlue: #007bff;
$darkBlue: #365c93;
$middleBlue: #007bff;
$fontBolder: 'Shippori Mincho', serif;
;

// backgrounds

$lightBackground: rgba(154, 154, 154, 0.252);
$midNavBackGround: #e0e2df;
$primaryBackground: #d3c6b3;
$windowBackground: #010101;
$backgroundColorDarkOpacity50: #01010199;
$backgroundColorLightOpacity50: rgba(253, 253, 253, 0.6);
$secondaryBeckground:#f9f7ea;
$regionalBackLightGreen: #c9dbe2;
$regionalBackDarkGreen:#346250;
$audioBackgroundColor: linear-gradient(0.25turn, #000000fd 10%, #0000005b  30%,  #00000078  50%);
$audioBackgroundRadial: linear-gradient(145deg, #00000016 10%, #00000036 40%, #00000044 80%, #00000081 95.71%),
                        linear-gradient(245deg, #e9e9e9a5 10%, #00000039 30.71% 55%, #0000002a 70% 80%, #0000004b 95.71%),
                        linear-gradient(180deg, #7070701d, #000000 80%);

// fonts

$homePageFont:'Shippori Mincho', serif;

// border
$buttonBorderLight: #e8e8e8;
$buttonBorderMid: #a79f9f;
// Loading
$loadingBackground: #504e4e84;
// input
$inputInactive: #6db197; 
$inputActive: #346250;

// button
$buttonInactiveGreen:#6db197;
$buttonActiveGreen:  #346250;
$buttonFontColorDark: #111111;
$buttonActiveFontColor: #ffffff;

// form
$formPrimeBackground: #ffffff;
$formHeaderColor: #4b4949;
$formBorderColor: #888888;
$wrapperBorder: .2rem solid #6db197;
$rowBorderColor: #c9dbe2;

// Yeni yesiller

$greenDark: #169650;
$greenLight: #87B273;


// Link

$linkBlue: #11afe2;


// Google
$white: #fff;
$google-blue: #4285f4;

// Email
$email-red: #f02d1bdc;

// player red 
$player-red: #ff0000da;

$starColor: #FFC107;

// border-bottom light black oppacity 28

$borderOppacity28: 1px solid rgba(17, 17, 17, 0.286);


// svg filters
$filterAltOne:invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
$filterAltTwo: invert(42%)  saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(100%);


// videos

$videosBackground: #010101;
$videosPrimaryFontColor: #ffffff;

// Audios-podcasts
$audiosYellow: #fed01d;