@import '../../../../utilities/css/color.module.scss';

.wrapper{
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    font-size: 200%;
    padding: 0 0.4em 0 0;
    .col{
        width: 0.25em !important;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        opacity: 0;
        visibility: hidden;
        padding: 0;
        flex-direction: column;
        cursor: pointer;
        position: relative;
        transition: visibility 0s, opacity 0.5s linear;
        .indicator{
            font-size: 0.25em;
            color: $whitePrimary;
            display: none;
            margin-top: 0 !important;
            position: absolute;
            height: 100%;
        }
        .indicator_active{
            font-size: 0.25em;
            color: $whitePrimary;
            position: absolute;
            margin-top: 0 !important;
            height: 100%;
        }
        .bar{
            width: 0.1em;
            height: 0.8em;
            background: $primaryLight;
            display: flex;
        }
        .bar_active{
            width: 0.1em;
            height: 1em;
            background: $whitePrimary;
            display: flex;
        }
    }
    .col:hover{
        .indicator{
            display: inline-block;
        }
        .bar{
            height: 1em;
            background: $whitePrimary;
        }
    }
    .volume_icon{
        color: $whitePrimary;
        cursor: pointer;
        height: 100%;
        width: 2em;
        padding: 0;
        margin: 0;
        align-items: center;
        display: flex;
        .icon{
            font-size: 1.2em;
        }
    }
}
.wrapper:hover{
    .col{
        opacity: 1;
        visibility: visible;
    }
    .volume_icon{
        color: $player-red;
    }
}
.bar_red{
    background: $player-red !important;
}

