@import '../../utilities/css/color.module.scss';

.container {
    width: 100%;
    display: flex;
    margin: 0;
    height: auto;
    justify-content: center;
    position: relative;
   .loading{
    width: 100%;
    height: 100vh;
    z-index: 4;
    position: fixed;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  }
  .main_container{
    width: 100%;
    margin: 0;
    height:auto; 
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }
  .videos_container{
    width: 100%;
    margin: 0;
    height:auto; 
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    background: $videosBackground;
  }
