@import '../../../../utilities/css/color.module.scss';
@keyframes comeFromDown{
    from {margin-top: 0; opacity: 0; height: 0;}
    to {margin-top: -5em; opacity: 1; height: 4.5em;}
}
.card{
    border: unset !important;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    .image{
        width: 90% !important;
        max-height: 65%;
        min-height: 65%;
        height: auto;
        cursor: pointer;
        align-self: center;
        filter: drop-shadow(8px 8px 6px rgba(0, 0, 0, 0.25));
        -webkit-filter: drop-shadow(8px 8px 6px rgba(0, 0, 0, 0.25));
    }
    .title{
        font-size: 0.8em;
        font-family: $primaryFont;
        line-height: 1.2em;
        color: $blackPrimary;
        height: 3em;
        margin: 0 0;
        cursor: pointer;
    }
    .author{
        font-size: 0.7em;
        font-family: $primaryFont;
        line-height: 1em;
        margin-top: 1em;
        color: $fontColorLight;
        height: 1em; 
        text-decoration: underline;
        cursor: pointer;
    }
    .stars{
        height: 1em;
        width: 100%;
        margin-top: 1em;
        margin: 0 !important;
        justify-content: center;
        display: flex;
        .rate{
            color: $starColor;
            font-size: 1em;
            justify-content: center;
          }
    }
}
.buttons_overlays{
    position: absolute;
    padding: 0.2em;
    width: 100% !important;
    margin-top: -5em;
    margin-left: -1.25rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    height: 4.5em;
    background-color: #fff;
    animation-name: comeFromDown;
    animation-duration: 0.3s;
    .addToButton{
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.135);
        margin: 0.1em 0.5em;
        font-size: 0.6em;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-transform: capitalize;
        color: #111111;
        transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
    }
    .downloadButton{
        border-radius: 2px !important;
        margin: 0.3em 0.5em;
        font-size: 0.6em;
        border: 1px solid rgba(0, 0, 0, 0.135) !important;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-transform: capitalize;
        color: $whitePrimary !important;
        background-color: $darkBlue !important;
    
    }
}
.skeleton{
    min-height: 60%;
    max-height: 70%;
    width: 100%;
}