@import '../../utilities/css/color.module.scss';

.container{
    position: relative;
    height: 96px;
    width: 100%;
    display: inline-grid;
    margin: 0;
    grid-template-columns: auto auto auto auto auto;
    cursor: pointer;
    .bannerGlobal{
        width: 100%;
        position: absolute;
        height: 96px;
        z-index: 0;
      }
      .midQuote{
        display: flex;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        z-index: 1;
        height: 100%;
        color: #FFFFFF;
        grid-column-start: 3;
        grid-column-end: 4;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
      .rightQuote{
        width: 198px;
        height: 100%;
        font-family: $primaryFont;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        z-index: 1;
        color: rgba(91, 251, 241, 0.8);
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
        grid-column-start: 4;
        grid-column-end: 5;
      }
}
.skeleton{
  height: 96px !important;
  width: 100%;
}