@import '../../../utilities/css/color.module.scss';
.container{
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}
.arrows{
    width: 100%;
    height: 90%;
    position: absolute;
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    pointer-events: none;
}
.carousel_wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
}
.arrow_button{
    font-size: 5em;
    cursor: pointer;
    color: $primaryLight;
    z-index: 1;
    transition: color 0.3s;
    pointer-events: auto; 
}
.arrow_button:hover{
    color: $blackPrimary;
}