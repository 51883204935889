
  .spinner{
    max-width: 100px;
    max-height: 100px;
  }
    .svg{
      width: 100%;
      height: 100%;
      border-radius: 8px;
      rect{
          width: 100%;
          height: 100%;
          fill: #e5e5e5;
      }
  }

  .container_page_full{
    padding-top: 50px;
    min-height: 200px;
    width: 100%;
    display: grid;
    grid-template-columns: 20%  20%  20% 20%;
    column-gap: 5%;
    justify-content: center;
    .rect{
      width: 100%;
      min-height: 150px;
    }
    .text{
      width: 100%;
      height: 20px;
    }
  }
  .nav_loading{
    width: 100%;
    height: 100px;
    .rect{
      width: 100%;
      height: 100%;
    }
  }