@import '../../../../utilities/css/color.module.scss';

@mixin padding_margin_zero ($width, $height) {
    padding: 0 !important;
    margin: 0;
    width: $width;
    min-height: $height;
}

.slider_container{
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    position: relative;
    @include padding_margin_zero(100%, 100% );
    .slider{
        height: 100% !important;
        width: 100%;
        display: inline;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        z-index: 1;
        margin: 0;
        padding: 0;
    }
    .progress_alternate{
        background: $primaryBackground;
        width: 100%;
        margin: 0;
        opacity: 0.3;
        height: 5px;
        border-radius: 2px;
        padding: 0;
        .played_time{
            background: red;
        }
        .buffered_time{
            background: $whitePrimary;
        }
    }
    
    .durations{
        color:  $whitePrimary;
        @include padding_margin_zero(100%, 80%);
        align-content: flex-end;
        .current_time{
            @include padding_margin_zero(50%,90%);
            display: flex;
            justify-content: flex-start;
        }
        .duration_time{
            @include padding_margin_zero(50%, 90%);
            justify-content: flex-end;
            display: flex;
        }
    }
}

.tool_tip{
    width: 50px !important;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
    padding: 2px !important;
}