@import '../../utilities//css/color.module.scss';

@mixin wrapper {
  @include flex_column();
  width: 100%;
  height: auto;
  align-content: center;
  padding: 40px 0 0 !important;
  margin: 40px 0 0 !important;
  font-family: $primaryFont;
}
@mixin content_general {
  width: 100%;
  padding: 0 20px 40px;
  margin: 0;
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 40%;
}
@mixin flex_column {
  display: flex;
  flex-direction: column;
}
@mixin link_header_general {
  max-width: 80%;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 0px 3px 10px;
  max-height: 30px;
}
@mixin link_general {
  padding-left: 3px;
  font-size: 14px;
  max-height: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  margin-top: 8px;
}
@mixin social_med_col_gen {
  margin: 0 0;
  padding-right: 20px;
  @include flex_column();
  align-content: flex-end;
}
@mixin social_med_header_gen {
  font-size: 14px;
  max-width: 50%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 5px;
  align-self: flex-end;
}
@mixin info_gen {
  width: 100%;
  height: 110px;
  padding: 8px 20px 0px;
  margin: 0;
}
.wrapper_light_background{
    @include wrapper();
    border-top: $borderOppacity28;
    .content{
      @include content_general();
      .link_group{
        @include flex_column();
        .link_header{
          @include link_header_general();
          color: $blackPrimary;
          border-bottom: 2px solid $blackPrimary;
        }
        .link{
          @include link_general();
          color:$fontColorLight;
        }
      }
      .social_media_col{
        @include social_med_col_gen();
       .social_media_header{
         @include social_med_header_gen();
        color: $blackPrimary;
        border-bottom: $borderOppacity28;
       }
      }
    }
    .info{
      @include info_gen();
      border-top:  $borderOppacity28;
      color: $blackPrimary;
    }
}
.wrapper_dark_background{
  @include wrapper();
  border-top: 1px solid $whitePrimary;
  .content{
    @include content_general();
    .link_group{
      @include flex_column();
      .link_header{
        @include link_header_general();
        color: $whitePrimary;
        border-bottom: 2px solid $whitePrimary;
      }
      .link{
        @include link_general();
        color:$fontColorLight;
        transition: color 0.2s;
      }
      .link:hover{
        color: $whitePrimary
      }
    }
    .social_media_col{
      @include social_med_col_gen();
     .social_media_header{
       @include social_med_header_gen();
      color: $whitePrimary;
      border-bottom: 1px solid $whitePrimary;
     }
    }
  }
  .info{
    @include info_gen();
    border-top:  1px solid $whitePrimary;
    color: $whitePrimary;
  }
}
  .social_media_icon{
      margin-top: 10px !important;
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
     img{
       width: 50px;
       cursor: pointer;
     }
  }
  .logo{
    margin-left: 0 !important;
    padding: 0 !important;
    img{
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
  }
  .info_content{
    display: flex;
    padding: 0 !important;
    flex-direction: column;
    .privacy_aggrement{
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      p{
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        cursor: pointer;
        margin-right: 16px;
        margin: 3px 0 0;
      }
    }
    .trademark{
      width: 100%;
      justify-content: flex-end;
      font-size: 8px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin: 0;
      padding-top: 10px;
    }
  }