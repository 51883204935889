@import '../../utilities/css/color.module.scss';

@mixin display_flex ($width, $height, $margin, $padding, $direction ) {
    padding: $padding !important;
    margin: $margin;
    width: $width;
    height: $height !important;
    display: flex;
    flex-direction: $direction;
}
@mixin logo {
    width: 80px;
    height: 80px;
    cursor: pointer;
    background-repeat: no-repeat;
    z-index: 1;
    background-size: 80px 80px !important;
}
@mixin link_general {
    @include display_flex(100px, 30px, unset, 5px, initial);
    font-family:$primaryFont;
    font-style: normal;
    font-weight: bolder;
    font-size: 13px;
    border-radius: 2px;
    line-height: 16px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    justify-content: space-around;
    margin-bottom: 3px;
    align-content: center;
    align-items: center;
}
@mixin login_button {
    @include display_flex(unset, unset, 2px 0 0 20px, 5px 5px, initial);
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 2px;
    transition: border 0.5s;
    cursor: pointer;
}
@mixin register_button {
    @include display_flex(unset, unset, 2px 0 0 14px, 5px 10px, initial);
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 2px;
    transition: background 0.5s;
    cursor: pointer;
    font-weight: bolder;
    text-transform: capitalize;
}
@mixin account_wrapper {
    @include display_flex(unset, 100%, 0, 0, row);
    justify-content: flex-end;
    align-items:center;
    flex-wrap: wrap;
}
@mixin library_button {
    @include display_flex(unset, unset, 2px 0 0 5px, 5px 3px, initial);
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4px;
    color: $fontColorLight;
    transition: border 2s;
    cursor: pointer;
}

.nav_general{
    @include display_flex(100%, 80px, 0, 0, column );
    align-items: center;
    z-index: 2;
    font-family: $primaryFont;
    .top_nav_bar{
        @include display_flex(100%, 80px, 0, 0 20px, row );
        align-items: center;
        .logo{
            @include logo();
            background: url('../../assets/logos/logo-dark.png');
        }
        .left_nav{
            @include display_flex(30%, 100%, 0, 0, row );
            justify-content: space-around;
            align-items: center;
            .link{
                @include link_general();
            }
            .link:hover{
                background-color: $lightBackground;
            }
            .active{
                @include link_general();
                background-color: $lightBackground;
            }
        }
        .account_wrapper{
            @include account_wrapper();
            .buttons{
                @include display_flex(unset, unset, initial, initial, row);
            .login{
                @include login_button();
                border: 1px solid $buttonBorderLight;
            }
            .login:hover{
                border: 1px solid $blackPrimary;
            }
            .register{
                @include register_button();
                background: $linkBlue;
                color: $whitePrimary;
            }
            .register:hover{
                background: $middleBlue;
            }
            .library{
                @include library_button();
                border: 1px solid $buttonBorderLight;
            }
            .library:hover{
                border: 1px solid $blackPrimary;
            }
        }
        }
    }
}

.nav_audios{
    @include display_flex(100%, 80px, 0, 0, column );
    align-items: center;
    z-index: 2;
    font-family: $primaryFont;
    background: $audiosYellow;
    .top_nav_bar{
        @include display_flex(100%, 80px, 0, 0 20px, row );
        align-items: center;
        .logo{
            @include logo();
            background: url('../../assets/logos/logo-dark.png');
        }
        .left_nav{
            @include display_flex(30%, 100%, 0, 0, row );
            justify-content: space-around;
            align-items: center;
            .link{
                @include link_general();
            }
            .link:hover{
                background-color: $lightBackground;
            }
            .active{
                @include link_general();
                background-color: $lightBackground;
            }
        }
        .account_wrapper{
            @include account_wrapper();
            .buttons{
                @include display_flex(unset, unset, initial, initial, row);
            .login{
                @include login_button();
                border: 2px solid $buttonBorderMid;
            }
            .login:hover{
                border: 2px solid $blackPrimary;
            }
            .register{
                @include register_button();
                background: $linkBlue;
                color: $whitePrimary;
            }
            .register:hover{
                background: $middleBlue;
            }
            .library{
                @include library_button();
                border: 1px solid $buttonBorderLight;
            }
            .library:hover{
                border: 1px solid $blackPrimary;
            }
        }
        }
    }
}
.nav_videos{
    @include display_flex(100%, 80px, 0, 0, column );
    align-items: center;
    z-index: 2;
    font-family: $primaryFont;
    color: $videosPrimaryFontColor;
    .top_nav_bar{
        @include display_flex(100%, 80px, 0,  0 20px, row );
        align-items: center;
        .logo{
            @include logo();
            background: url('../../assets/logos/papi-kitap-logo-white.png');
        }
        .left_nav{
            @include display_flex(30%, 100%, 0, 0, row );
            justify-content: space-around;
            align-items: center;
            .link{
                @include link_general();
            }
            .link:hover{
                background-color: $lightBackground;
            }
            .active{
                @include link_general();
                background-color: $lightBackground;
            }
        }
        .account_wrapper{
            @include account_wrapper();
            .buttons{
                @include display_flex(unset, unset, initial, initial, row);
            .login{
                @include login_button();
                border: 1px solid $primaryLight;
            }
            .login:hover{
                border: 1px solid $whitePrimary;
            }
            .register{
                @include register_button();
                background: $linkBlue;
            }
            .register:hover{
                background: $middleBlue;
            }
            .library{
                @include library_button();
                border: 1px solid $buttonBorderLight;
            }
            .library:hover{
                border: 1px solid $blackPrimary;
            }
        }
        }
    }
}