@import '../../../utilities/css/color.module.scss';
.hidden{
    display: none;
}
.addToButton{
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.135);
    margin: 0.5em;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    color: #111111;
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
.downloadButton{
    border-radius: 2px !important;
    margin: 0.5em;
    border: 1px solid rgba(0, 0, 0, 0.135) !important;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    color: $whitePrimary !important;
    background-color: $darkBlue !important;

}